// App.js
import React from "react";
import { Routes, Route } from "react-router-dom"; // Ensure React Router v6 is used
import Layout from "./components/Layout";
import Home from "./components/Home";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"; // For Vercel Speed Insights
import Maintenance from "./components/Maintenance"; // Import Maintenance component
import ContactPage from "./components/Contact";

function App() {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  return (
    <>
      {isMaintenanceMode ? (
        <Maintenance /> // Show Maintenance page if the flag is true
      ) : (
        <>
          <Routes>
            {/* Parent Route with Layout */}
            <Route path="/" element={<Layout />}>
              {/* Nested Routes */}
              <Route index element={<Home />} /> {/* Renders at "/" */}
              <Route path="contact" element={<ContactPage />} />
              {/* Add more nested routes here if needed */}
            </Route>
          </Routes>
          <SpeedInsights />
          <Analytics />
        </>
      )}
    </>
  );
}

export default App;
