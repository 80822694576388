// SearchForm.js
import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Autocomplete,
  Typography,
} from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers/PickersDay"; // Import PickersDay
import { FlightTakeoff, FlightLand, Add, Remove } from "@mui/icons-material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import LockIcon from "@mui/icons-material/Lock";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import StarIcon from "@mui/icons-material/Star";
import CommentIcon from "@mui/icons-material/Comment";

const CustomPickersDay = (props) => {
  const { day, departureDate, ...other } = props;
  const isDepartureDay =
    departureDate && day.toDateString() === departureDate.toDateString();

  return (
    <PickersDay
      {...other}
      sx={{
        ...(isDepartureDay && {
          backgroundColor: "primary.main",
          color: "white",
          "&:hover, &:focus": {
            backgroundColor: "primary.dark",
          },
        }),
      }}
    />
  );
};

/**
 * SearchForm Component
 * Handles flight search with validation for origin, destination, dates, and adults.
 */

const SearchForm = ({
  originOptions,
  originInput,
  setOriginInput,
  origin,
  setOrigin,
  destinationOptions,
  destinationInput,
  setDestinationInput,
  destination,
  setDestination,
  departureDate,
  setDepartureDate,
  returnDate,
  setReturnDate,
  adults,
  setAdults,
  handleSearch,
  filterAirports,
  flightType, // Indicates 'roundtrip' or 'oneway'
  setFlightType, // Function to set flight type
  airbnbType,
  setAirbnbType,
}) => {
  const [errors, setErrors] = useState({});
  const [isDepartureOpen, setIsDepartureOpen] = useState(false);
  const [isReturnOpen, setIsReturnOpen] = useState(false);

  const validateFields = () => {
    const newErrors = {};
    if (!origin) newErrors.origin = "Origin is required";
    if (!destination) newErrors.destination = "Destination is required";
    if (!departureDate) newErrors.departureDate = "Departure date is required";
    if (origin && destination && origin.city === destination.city) {
      newErrors.origin = "Origin and destination cannot be the same";
      newErrors.destination = "Origin and destination cannot be the same";
    }

    if (
      flightType === "roundtrip" &&
      (!returnDate || returnDate < departureDate)
    ) {
      newErrors.returnDate = "Return date must be after the departure date";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = () => {
    if (validateFields()) {
      handleSearch(); // Proceed only if all fields are valid
    }
  };

  return (
    <>
      <Box
        sx={{
          width: {
            xs: "320px", // Mobile
            sm: "550px", // Halfscreen
            md: "800px", // Desktop
          },
          position: "relative",
        }}
      >
        {/* Hero Title */}
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: "900",
            mb: 2,
            fontFamily: "'Roboto', sans-serif",
            textAlign: "center",
            color: "white",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
            letterSpacing: "1.5px",
            lineHeight: "1.2",
            fontSize: {
              xs: "2.5rem", // Mobile font size
              sm: "2.5rem", // Halfscreen
              md: "3rem", // Desktop
            },
          }}
        >
          One Search:{" "}
          <Box component="span" sx={{ color: "primary.flight" }}>
            Flights
          </Box>{" "}
          +{" "}
          <Box component="span" sx={{ color: "primary.stay" }}>
            Airbnb
          </Box>
        </Typography>

        {/* Hero Subtitle */}
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontWeight: "300",
            mb: 2,
            fontFamily: "'Roboto', sans-serif",
            textAlign: "center",
            color: "text.secondary",
            maxWidth: "1200px",
            mx: "auto",
            fontSize: {
              xs: "1rem", // Mobile font size
              sm: "0.9rem", // Halfscreen
              md: "1.2rem", // Desktop
            },
          }}
        >
          Built by a traveler for travelers. FlyBnB shows you flight prices and
          Airbnb averages in seconds, with links to book them. Get total trip
          costs without the hassle.
        </Typography>
      </Box>

      {/* Form Container */}
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "1200px",
          mx: "auto",
          mt: 0,
          mb: 2,
          gap: 2,
        }}
      >
        {/* Flight Type Selection */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            mb: 1,
            mr: 1,
            width: "100%",
          }}
        >
          <ToggleButtonGroup
            value={flightType}
            exclusive
            onChange={(e, newValue) => {
              if (newValue !== null) setFlightType(newValue);
            }}
            sx={{
              width: "250px", // Adjust width to fit content with icons
              height: "40px", // Slightly taller for better alignment
              borderRadius: "20px",
              overflow: "hidden",
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              border: "1.2px solid gray",
              "& .MuiToggleButton-root": {
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center", // Center the content including icons
                fontWeight: "500",
                fontSize: "0.775rem",
                color: "#858383",
                padding: "6px 12px",
                gap: 0.5, // Space between text and icon
                transition: "all 0.2s ease",
                border: "none",
                outline: "none",
                "&.Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "primary.main", // Slightly darker hover effect for selected
                  },
                },
                "&:focus": {
                  backgroundColor: "primary.main", // Maintain selected color on focus
                  color: "white",
                },
                "&:active": {
                  backgroundColor: "primary.main", // Active state for touch feedback
                  color: "white",
                },
              },
            }}
          >
            <ToggleButton value="roundtrip" aria-label="Roundtrip">
              <SyncAltIcon fontSize="small" />
              Roundtrip
            </ToggleButton>
            <ToggleButton value="oneway" aria-label="One-way">
              <ArrowRightAltIcon fontSize="small" />
              One-way
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Origin and Destination Autocomplete Fields */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1, md: 1 },
            width: "88%",
            maxWidth: "800px",
            justifyContent: "center",
            alignItems: "center",
            mx: "auto",
          }}
        >
          {/* Origin Autocomplete */}
          <Autocomplete
            options={originOptions}
            getOptionLabel={(option) => `${option.city}, ${option.country}`}
            inputValue={originInput}
            onInputChange={(e, newInputValue) => {
              setOriginInput(newInputValue);
              if (newInputValue === "") {
                setOrigin(null); // Clear the selected origin when input is empty
              }
            }}
            value={origin}
            onChange={(e, newValue) => setOrigin(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Origin"
                error={!!errors.origin}
                helperText={errors.origin}
                placeholder="Type city or airport code"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FlightTakeoff />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            filterOptions={(options, { inputValue }) =>
              filterAirports(inputValue)
            }
            sx={{
              flex: 1,
              minWidth: { xs: "100%", md: "80%" },
              maxWidth: "300px",
            }}
          />

          {/* Destination Autocomplete */}
          <Autocomplete
            options={destinationOptions}
            getOptionLabel={(option) => `${option.city}, ${option.country}`}
            inputValue={destinationInput}
            onInputChange={(e, newInputValue) => {
              setDestinationInput(newInputValue);
              if (newInputValue === "") {
                setDestination(null); // Clear the selected destination when input is empty
              }
            }}
            value={destination}
            onChange={(e, newValue) => setDestination(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Destination"
                error={!!errors.destination}
                helperText={errors.destination}
                placeholder="Type city or airport code"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FlightLand />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            filterOptions={(options, { inputValue }) =>
              filterAirports(inputValue)
            }
            sx={{
              flex: 1,
              minWidth: { xs: "100%", md: "80%" },
              maxWidth: "300px",
            }}
          />
        </Box>

        {/* Date Pickers */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1, md: 1 },
            width: "88%",
            maxWidth: "800px",
            justifyContent: "center",
            alignItems: "center",
            mx: "auto",
          }}
        >
          {/* Departure Date Picker */}
          <DesktopDatePicker
            label="Departure Date"
            value={departureDate}
            minDate={new Date()}
            onChange={(newValue) => setDepartureDate(newValue)}
            open={isDepartureOpen}
            onOpen={() => setIsDepartureOpen(true)}
            onClose={() => setIsDepartureOpen(false)}
            disablePortal
            disableCloseOnSelect={false} // Ensures calendar closes on selection
            renderDay={(day, selectedDates, pickersDayProps) => (
              <CustomPickersDay
                {...pickersDayProps}
                day={day}
                departureDate={departureDate}
              />
            )}
            slots={{
              textField: TextField, // Specify TextField as the component for textField
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "outlined",
                inputProps: {
                  readOnly: true,
                  onClick: () => setIsDepartureOpen(true), // Open calendar on click
                },
                error: !!errors.departureDate,
                helperText: errors.departureDate,
              },
            }}
            onError={(reason, value) => {
              if (reason === "invalidDate") {
                setErrors((prev) => ({
                  ...prev,
                  departureDate: "Please enter a valid departure date",
                }));
              } else {
                // Clear the error if the reason is not 'invalidDate'
                setErrors((prev) => ({
                  ...prev,
                  departureDate: undefined,
                }));
              }
            }}
            sx={{
              flex: 1,
              minWidth: { xs: "100%", md: "80%" },
              maxWidth: "300px",
            }}
          />

          {/* Return Date Picker (Visible Only for Roundtrip) */}
          {(flightType === "roundtrip" || flightType === "oneway") && (
            <DesktopDatePicker
              label={
                flightType === "roundtrip" ? "Return Date" : "Checkout Date"
              } // Dynamically set the label
              value={returnDate}
              minDate={departureDate || new Date()}
              onChange={(newValue) => setReturnDate(newValue)}
              open={isReturnOpen}
              onOpen={() => setIsReturnOpen(true)}
              onClose={() => setIsReturnOpen(false)}
              disablePortal
              disableCloseOnSelect={false} // Ensures calendar closes on selection
              renderDay={(day, selectedDates, pickersDayProps) => (
                <CustomPickersDay
                  {...pickersDayProps}
                  day={day}
                  departureDate={departureDate}
                />
              )}
              slots={{ textField: TextField }} // Use MUI's default TextField behavior
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  inputProps: {
                    readOnly: true, // Prevent manual input
                    onClick: () => setIsReturnOpen(true), // Open calendar on click
                  },
                  error: !!errors.returnDate,
                  helperText: errors.returnDate,
                },
              }}
              onError={(reason) => {
                if (reason === "invalidDate") {
                  setErrors((prev) => ({
                    ...prev,
                    returnDate: "Please enter a valid return date",
                  }));
                } else if (reason === "minDate") {
                  setErrors((prev) => ({
                    ...prev,
                    returnDate: "Return date cannot be before departure date",
                  }));
                } else {
                  setErrors((prev) => ({
                    ...prev,
                    returnDate: undefined,
                  }));
                }
              }}
              sx={{
                flex: 1,
                minWidth: { xs: "100%", md: "80%" },
                maxWidth: "300px",
              }}
            />
          )}
        </Box>

        <Accordion
          sx={{
            width: "100%",
            maxWidth: "1200px",
            backgroundColor: "#121212", // Match page background
            mx: "auto",
            mt: 1,
            boxShadow: "none", // No shadow
            borderRadius: "8px", // Optional rounding for smoothness
            "&::before": {
              display: "none", // Remove divider line
            },
          }}
          disableGutters
        >
          <AccordionSummary
            aria-controls="advanced-options-content"
            id="advanced-options-header"
            sx={{
              px: 1, // Adjust padding
              py: 0.5,
              minHeight: "unset", // Allow content to define height
              backgroundColor: "#121212", // Ensure matching background
              "& .MuiAccordionSummary-content": {
                margin: 0,
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                More Options
              </Typography>
              <ExpandMoreIcon
                sx={{
                  transition: "transform 0.3s ease", // Smooth rotation animation
                  transform: (theme) =>
                    theme.expanded ? "rotate(180deg)" : "rotate(0deg)", // Rotate on expand
                }}
              />
            </Box>
          </AccordionSummary>

          <AccordionDetails sx={{ px: 1, pt: 1, backgroundColor: "#121212" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
                width: "100%",
                maxWidth: "1200px",
                mx: "auto",
                mt: 0,
                mb: 2.5,
                flexWrap: "nowrap", // Prevent wrapping
                flexDirection: { xs: "column", sm: "row" }, // New line
              }}
            >
              {/* Adults Selector with Icon */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 0,
                  minWidth: "200px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    mb: 1,
                  }}
                >
                  <PersonIcon sx={{ color: "white" }} />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "normal",
                      color: "text.primary",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Adults
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <IconButton
                    onClick={() => setAdults(Math.max(1, adults - 1))}
                    aria-label="decrease adults"
                    sx={{
                      backgroundColor: "primary.secondary",
                      "&:hover": { backgroundColor: "primary.light" },
                    }}
                  >
                    <Remove />
                  </IconButton>

                  <TextField
                    value={adults}
                    inputProps={{
                      readOnly: true,
                      style: { textAlign: "center" },
                    }}
                    variant="outlined"
                    sx={{ width: "60px", textAlign: "center" }}
                  />

                  <IconButton
                    onClick={() => setAdults(Math.min(10, adults + 1))}
                    aria-label="increase adults"
                    sx={{
                      backgroundColor: "primary.secondary",
                      "&:hover": { backgroundColor: "primary.light" },
                    }}
                  >
                    <Add />
                  </IconButton>
                </Box>
              </Box>

              {/* Airbnb Type Toggle Group with Icon */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                  maxWidth: "700px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    mb: 1,
                  }}
                >
                  <HomeIcon sx={{ color: "white" }} />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "normal",
                      color: "text.primary",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Type of Stay
                  </Typography>
                </Box>

                <ToggleButtonGroup
                  value={airbnbType}
                  exclusive
                  onChange={(e, newValue) => {
                    if (newValue !== null) setAirbnbType(newValue);
                  }}
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    overflow: "hidden",
                    "& .MuiToggleButton-root": {
                      flex: 1,
                      textTransform: "none",
                      fontSize: "0.9rem",
                      padding: "10px 16px",
                      border: "2px solid",
                      borderColor: "divider",
                      whiteSpace: "nowrap",
                      "&.Mui-selected": {
                        backgroundColor: "primary.main",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "primary.dark",
                        },
                      },
                      "&:not(:first-of-type)": {
                        borderLeft: "none",
                      },
                      "&:first-of-type": {
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      },
                      "&:last-of-type": {
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                      },
                    },
                  }}
                >
                  <ToggleButton value="both" aria-label="Any">
                    Any
                  </ToggleButton>
                  <ToggleButton value="entire_home" aria-label="Entire Home">
                    Entire Home
                  </ToggleButton>
                  <ToggleButton value="private_room" aria-label="Private Room">
                    Private Room
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>

            {/* New Review Score/Count Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 0,
                mt: 1,
                mb: 0,
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 1,
                  mt: 3,
                }}
              >
                <LockIcon sx={{ fontSize: "1rem", color: "grey.500" }} />
                <Typography variant="caption" sx={{ color: "grey.500" }}>
                  Coming Soon
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                  gap: 6, // Adjust the gap to align with Adults field styling
                  justifyContent: "center",
                  width: "100%",
                  maxWidth: "600px",
                  flexWrap: "wrap", // Ensure responsiveness on smaller screens
                }}
              >
                {/* Min. Rating */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <StarIcon sx={{ color: "white", fontSize: "17px" }} />{" "}
                    {/* Adjust fontSize */}
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "normal",
                        color: "text.primary",
                        letterSpacing: "0.5px",
                        mb: 0.5,
                        ml: 0.5,
                      }}
                    >
                      Min. Rating
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <TextField
                      value="4.0"
                      inputProps={{
                        readOnly: true,
                        style: { textAlign: "center", fontWeight: "bold" },
                      }}
                      variant="outlined"
                      disabled
                      sx={{
                        width: "60px",
                        "& .Mui-disabled": {
                          backgroundColor: "rgba(255, 255, 255, 0.2)", // Grayed out for disabled
                          color: "rgba(255, 255, 255, 0.8)",
                        },
                      }}
                    />
                  </Box>
                </Box>

                {/* Min. Reviews */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <CommentIcon sx={{ color: "white", fontSize: "17px" }} />{" "}
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "normal",
                        color: "text.primary",
                        letterSpacing: "0.5px",
                        mb: 0.5,
                        ml: 0.5,
                      }}
                    >
                      Min. Reviews
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <TextField
                      value="10"
                      inputProps={{
                        readOnly: true,
                        style: { textAlign: "center", fontWeight: "bold" },
                      }}
                      variant="outlined"
                      disabled
                      sx={{
                        width: "60px",
                        "& .Mui-disabled": {
                          backgroundColor: "rgba(255, 255, 255, 0.2)", // Grayed out for disabled
                          color: "rgba(255, 255, 255, 0.8)",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Search Button */}
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            mt: { xs: 2, sm: 2, md: 2, lg: 2 },
            width: "100%",
            maxWidth: "200px",
          }}
          onClick={handleFormSubmit}
        >
          Search
        </Button>
      </Box>
    </>
  );
};

export default SearchForm;
