const isLoggingEnabled = process.env.REACT_APP_ENABLE_LOGS === "true";

export const logger = {
  log: (...args) => {
    if (isLoggingEnabled) {
      console.log(...args);
    }
  },
  warn: (...args) => {
    if (isLoggingEnabled) {
      console.warn(...args);
    }
  },
  error: (...args) => {
    if (isLoggingEnabled) {
      console.error(...args);
    }
  },
  time: (label) => {
    if (isLoggingEnabled) {
      console.time(label);
    }
  },
  timeEnd: (label) => {
    if (isLoggingEnabled) {
      console.timeEnd(label);
    }
  },
};
