// src/components/Subscribe.js

import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm, ValidationError } from "@formspree/react";

const Subscribe = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isDisabled] = useState(false); // Toggle to enable/disable API
  const [state, handleSubmit] = useForm("xanyovgy");

  return (
    <Box
      sx={{
        py: 8,
        px: 3,
        backgroundColor: "#272727",
        textAlign: "center",
      }}
    >
      {/* Heading */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "800",
          mb: 2,
          fontFamily: "'Roboto', sans-serif",
          textAlign: "center",
          color: "white",
        }}
      >
        Subscribe to stay updated!
      </Typography>

      {/* Description */}
      <Typography
        variant="body1"
        sx={{
          mb: 3,
          color: "text.secondary",
          maxWidth: "700px",
          mx: "auto",
        }}
      >
        Get updates on new features, improvements, and Flight + Airbnb deals
        (soon)...
      </Typography>

      {/* Subscription Form */}
      {!state.succeeded ? (
        <form
          onSubmit={(e) => {
            if (isDisabled) {
              e.preventDefault();
              alert("Subscription is currently disabled for debugging.");
              return;
            }
            handleSubmit(e);
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? 2 : 1,
              mb: 3,
            }}
          >
            <TextField
              label="Enter your email"
              type="email"
              name="email"
              variant="outlined"
              size="small"
              error={!!state.errors?.find((err) => err.field === "email")}
              helperText={
                state.errors?.find((err) => err.field === "email")?.message
              }
              sx={{
                maxWidth: "300px",
                width: "100%",
                mb: isMobile ? 0 : 0,
              }}
              InputProps={{
                sx: {
                  height: "45px", // Match height of contact form
                },
              }}
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={state.submitting}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "1rem",
                minHeight: "45px", // Match TextField height
                padding: isMobile ? "8px 16px" : "0 24px", // Adjust padding based on screen size
                width: isMobile ? "auto" : "auto",
              }}
            >
              Subscribe
            </Button>
          </Box>
        </form>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            mt: 4,
            maxWidth: "600px", // Set max width for the box
            mx: "auto", // Center the box horizontally
          }}
        >
          <Alert
            severity="success"
            sx={{
              mt: 3,
              mb: 3,
              fontSize: "1.25rem",
              fontWeight: "bold",
              textAlign: "center", // Center the text inside the Alert
            }}
          >
            You’re now subscribed. Great stuff awaits! 🎉
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default Subscribe;
