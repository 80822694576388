import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";

const Explainer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4, md: 6 },
      }}
    >
      {/* Flights Row */}
      <Grid
        container
        spacing={4}
        direction={isMobile ? "column" : "row"}
        alignItems="center"
      >
        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="/eflight.png" // Correctly reference the image in the public folder
            alt="Flights Illustration"
            sx={{
              width: "100%", // Limit the width to 80% of its container
              maxWidth: "410px", // Set a max width for the image
              height: "auto",
              borderRadius: 2,
              boxShadow: 3,
              mx: "right", // Center the image horizontally within its grid
            }}
          />
        </Grid>

        {/* Text Content Section */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "text.primary",
            }}
          >
            Flight Options at a Glance
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              color: "text.secondary",
              lineHeight: 1.6,
            }}
          >
            Discover the best flight options for your trip. Whether you're
            looking for the fastest route, the cheapest fare, or the best value
            deal, FlyBnB has you covered. Here's what the Flights results mean:
          </Typography>

          {/* Numbered Points with Images */}
          <Box>
            {/* Point 1 */}
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Box
                  component="img"
                  src="/numbers/1.svg" // Path to your number image
                  alt="1"
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    lineHeight: 1.6,
                  }}
                >
                  Quick filters for 'Best' (best value for money), 'Cheapest'
                  (the lowest cost option), and 'Fastest' (shortest trip
                  duration).
                </Typography>
              </Grid>
            </Grid>

            {/* Point 2 */}
            <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
              <Grid item>
                <Box
                  component="img"
                  src="/numbers/2.svg" // Path to your number image
                  alt="2"
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    lineHeight: 1.6,
                  }}
                >
                  See the full travel itinerary in both legs (if round-trip),
                  including stops, airline, flight number, timings, and total
                  duration.
                </Typography>
              </Grid>
            </Grid>

            {/* Point 3 */}
            <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
              <Grid item>
                <Box
                  component="img"
                  src="/numbers/3.svg" // Path to your number image
                  alt="3"
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    lineHeight: 1.6,
                  }}
                >
                  Select 'Book on SkyScanner' to visit your selected search on
                  SkyScanner and complete the booking there in a familiar
                  environment.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Stays Row */}
      <Grid
        container
        spacing={4}
        direction={isMobile ? "column" : "row-reverse"}
        alignItems="center"
        sx={{ mt: 6 }}
      >
        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="/explain-stay2.png" // Correctly reference the image in the public folder
            alt="Stays Illustration"
            sx={{
              width: "100%", // Limit the width to 80% of its container
              maxWidth: "460px", // Set a max width for the image
              height: "auto",
              borderRadius: 2,
              boxShadow: 3,
              mx: "auto", // Center the image horizontally within its grid
            }}
          />
        </Grid>

        {/* Text Content Section */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "text.primary",
            }}
          >
            Stay Options Instantly Delivered
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              color: "text.secondary",
              lineHeight: 1.6,
            }}
          >
            Explore Airbnb listings that match your budget and style. Filter the
            type of stay (entire home or private room) and set a minimum review
            score & count. Here's what the Stays results mean:
          </Typography>

          {/* Numbered Points with Images */}
          <Box>
            {/* Point 1 */}
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Box
                  component="img"
                  src="/numbers/1.svg" // Path to your number image
                  alt="1"
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    lineHeight: 1.6,
                  }}
                >
                  View listings split into three price ranges: Economy,
                  Standard, and Premium, with average prices of each range
                  listed.
                </Typography>
              </Grid>
            </Grid>

            {/* Point 2 */}
            <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
              <Grid item>
                <Box
                  component="img"
                  src="/numbers/2.svg" // Path to your number image
                  alt="2"
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    lineHeight: 1.6,
                  }}
                >
                  Get a summary of each listing within a price category,
                  including listing image, ratings, and exact price for your
                  dates.
                </Typography>
              </Grid>
            </Grid>

            {/* Point 3 */}
            <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
              <Grid item>
                <Box
                  component="img"
                  src="/numbers/3.svg" // Path to your number image
                  alt="3"
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    lineHeight: 1.6,
                  }}
                >
                  Use the "Book on Airbnb" button to navigate directly to
                  Airbnb, where you can further view the listing & complete your
                  booking.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Explainer;
