// src/components/Maintenance.js
import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  useMediaQuery,
} from "@mui/material";
import { useForm, ValidationError } from "@formspree/react";

const Maintenance = () => {
  const [state, handleSubmit] = useForm("xanyovgy"); // Use your Formspree form ID
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#121212",
        color: "#fff",
        textAlign: "center",
        padding: 2,
      }}
    >
      {/* Logo */}
      <Box
        sx={{
          mb: 0, // Add some space below the logo
        }}
      >
        <img
          src="logo-header.svg" // Update this to your actual logo path
          alt="Your Logo"
          style={{
            maxWidth: "1500px", // Adjust to fit your needs
            width: "100%",
            height: "auto",
          }}
        />
      </Box>
      <Box
        sx={{
          width: {
            xs: "320px", // Mobile
            sm: "550px", // Halfscreen
            md: "800px", // Desktop
          },
          position: "relative",
        }}
      >
        {/* Hero Title */}
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: "900",
            mb: 5,
            fontFamily: "'Roboto', sans-serif",
            textAlign: "center",
            color: "white",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
            letterSpacing: "1.5px",
            lineHeight: "1.2",
            fontSize: {
              xs: "2.5rem", // Mobile font size
              sm: "2.5rem", // Halfscreen
              md: "3rem", // Desktop
            },
          }}
        >
          One Search:{" "}
          <Box component="span" sx={{ color: "primary.flight" }}>
            Flights
          </Box>{" "}
          +{" "}
          <Box component="span" sx={{ color: "primary.stay" }}>
            Airbnb
          </Box>
        </Typography>
      </Box>

      <Typography variant="h3" sx={{ mb: 2 }}>
        🚧 Maintenance 🚧
      </Typography>
      <Typography
        variant="h6"
        sx={{ maxWidth: "600px", lineHeight: 1.6, mb: 4 }}
      >
        Performing some maintenance to improve your experience.<br></br>
        Check back later or subscribe below for updates!
      </Typography>

      {/* Subscription Section */}
      <Box sx={{ width: "100%", maxWidth: "600px" }}>
        <Typography variant="body1" sx={{ mb: 3, color: "text.secondary" }}>
          Sign up to get updates on when we're back online and new features.
        </Typography>
        {!state.succeeded ? (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? 2 : 1,
                mb: 3,
              }}
            >
              <TextField
                label="Enter your email"
                type="email"
                name="email"
                variant="outlined"
                size="small"
                error={!!state.errors?.find((err) => err.field === "email")}
                helperText={
                  state.errors?.find((err) => err.field === "email")?.message
                }
                sx={{
                  maxWidth: "300px",
                  width: "100%",
                }}
                InputProps={{
                  sx: {
                    height: "45px", // Match button height
                  },
                }}
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={state.submitting}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  minHeight: "45px", // Match TextField height
                  padding: isMobile ? "8px 16px" : "0 24px", // Adjust padding based on screen size
                  width: isMobile ? "100%" : "auto",
                }}
              >
                Subscribe
              </Button>
            </Box>
          </form>
        ) : (
          <Alert
            severity="success"
            sx={{
              mt: 3,
              mb: 3,
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            You’re now subscribed. Great stuff awaits! 🎉
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default Maintenance;
