import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [submissionError, setSubmissionError] = useState(false); // For handling API errors
  const [isDisabled] = useState(false); // Toggle this to enable/disable form submission
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validate = () => {
    let errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      errors.email = "Email is invalid";
    if (!formData.message) errors.message = "Message is required";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isDisabled) {
      alert("Form submissions are currently disabled for debugging.");
      setFormSubmitted(true); // Show confirmation message

      return;
    }

    const errors = validate();
    if (Object.keys(errors).length === 0) {
      try {
        await axios.post("https://formspree.io/f/xdkogbrn", formData);
        setFormSubmitted(true); // Show confirmation message
      } catch (error) {
        setSubmissionError(true); // Show error if Formspree request fails
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 5,
        mb: 10,
      }}
    >
      {!formSubmitted ? (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Typography variant="h4" gutterBottom>
            Get in Touch
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
            Have a question? Found a bug? Want to request a feature? <br />
            Send me a message, and I'll get back to you ASAP!
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ width: "100%", mt: 2 }}
            noValidate
            autoComplete="off"
          >
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!formErrors.name}
              helperText={formErrors.name}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              multiline
              rows={4}
              error={!!formErrors.message}
              helperText={formErrors.message}
              sx={{ mb: 3 }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                padding: "12px",
                fontSize: "1rem",
                fontWeight: "bold",
                textTransform: "none",
              }}
            >
              Send Message
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Alert
            severity="success"
            sx={{ mt: 20, mb: 20, fontSize: "1.25rem", fontWeight: "bold" }} // Adjust font size and weight here
          >
            🎉 Thank you! Got your message, will get back ASAP!
          </Alert>
        </Box>
      )}

      {/* Error Snackbar */}
      {submissionError && (
        <Snackbar
          open={submissionError}
          autoHideDuration={6000}
          onClose={() => setSubmissionError(false)}
        >
          <Alert
            onClose={() => setSubmissionError(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Oops! Something went wrong. Please try again later.
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default Contact;
