import React from "react";
import ContactForm from "./ContactForm"; // Adjust path if necessary
import { Box } from "@mui/material";

const ContactPage = () => {
  return (
    <Box
      sx={{
        px: { xs: 3, sm: 4, md: 6 }, // Adjusts horizontal padding (left and right)
        maxWidth: "600px", // Limit maximum width for better readability
        margin: "0 auto", // Centers the form horizontally
      }}
    >
      <ContactForm />
    </Box>
  );
};

export default ContactPage;
