// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import theme from "./theme";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* Wrap everything in BrowserRouter */}
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* Applies the theme's background color */}
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
